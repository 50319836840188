import React from 'react'

const Phrase = () => {
  return (
   <section className="phrase" style={{backgroundImage:"linear-gradient(to bottom right, rgba(0,0,0,0), rgba(0,0,0,0.6)),url(images/banner-hero.jpg)", height:400}}>
  <h2 className='phrase__title'>  Skilled professionals are always ready to provide reliable services to our clients!</h2>
   </section>
  )
}

export default Phrase