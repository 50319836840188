import React from 'react';
import { motion} from "framer-motion"

const About = () => {
  return (
   <motion.section id="about" className="about container"
   initial={{ opacity: 0, y:100 }}
   transition={{duration:1}}
   whileInView={{opacity:1, y:105}}
  viewport={{amount:0.3}}
   >
    <div className='about__col_left'>
    <img src="/images/people-about.jpg" alt="Team" className="about__image about__image1"/>
<p className="about__paragraph about__para1">
Our business was funded by a consortium of leading technical Management 
Consultants. With a combined experience of 25 years of project delivery 
across a multitude of business types and disciplines. We specialise in 
The successful delivery of technology and process intensive projects
</p>
    </div>
    <div className='about__col_right'>
    <h2 className="about__title">About Us</h2>
    <p className="about__paragraph about__para2">
Our framework starts from the people as in most cases the people ( the customer or
the workers ) give the business the footprint. We have a defined dynamic framework 
which capture core requirements and marrys these with business needs and goals
The SND framework has delivered many successful projects globally.
</p>
<img src="/images/slider3.jpg" alt="Team" className='about__image about__image2' />
    </div>
   </motion.section>
  )
}

export default About