import React from 'react'
import { FaBriefcase,FaHeadset, FaUsers } from 'react-icons/fa';
import { motion} from "framer-motion"


const Trusted = () => {
  return (
   <motion.section className="trusted container"
   initial={{ opacity: 0}}
   transition={{duration:1}}
   whileInView={{opacity:1}}
  viewport={{amount:0.5}}
   >
    <div className="trusted__box bg-white">
   < FaBriefcase className='trusted__icon color-red'/>
            <h2 className="trusted__title">Trusted Services</h2>
            <p className='trusted__paragraph'>
            We have a collective 25 years experience in the delivery of Technology Solutions
            Our pedigree is recognised by leading vendors including Microsoft and IBM
            </p>
    </div>
    <div className="trusted__box bg-red">
    <FaHeadset className='trusted__icon color-white'/>
            <h2  className="trusted__title" style={{color:"white"}}>Support</h2>
            <p className='trusted__paragraph' style={{color:"white"}}>
            Our solutions are fully guaranteed and we provide support and documentation 
            as part of our standard project delivery
            </p>
    </div>
    <div className="trusted__box bg-white">
    <FaUsers className='trusted__icon color-red'/>
            <h2 className="trusted__title">Expert And Professional</h2>
            <p className='trusted__paragraph'>
            We have a tried and tested professional delivery methodology which is designed
            To delivery a flawless and timeless modular solution for your business
            </p>
    </div>
   </motion.section>
  )
}

export default Trusted