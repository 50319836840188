import React from 'react'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope} from 'react-icons/fa';
const Footer = () => {
  return (
   <section className="footer">

    <div className="container">
      <hr className='color-white' />
        <div className="footer__content">
          <div className="footer__box">
       <h2 className='footer__title'>   Gotosndigital</h2>

<p className='footer__paragraph'>We use tried and tested business analysis tools and forward diversified strategies to help redefine your business and facilitate the move to digital </p>
          </div>
          <div className="footer__box">
    <h2 className="footer__title">Services</h2>
    <ul className="footer__list">
    <li className='footer__li'>Business Process</li>
    <li className='footer__li'>Development Platform</li>
    <li className='footer__li'>Data CRM</li>
    </ul>
</div>
{/* <!-- Services --> */}
<div className="footer__box">
<h2 className="footer__title">Contact Us</h2>

<p className="footer__paragraph"><FaMapMarkerAlt className='footer__icon'/> 207 Regent Street London W1B 3HH</p>
<p className="footer__paragraph"><FaPhoneAlt className='footer__icon'/>0330 043 5871 / 07501 672 115</p>
<p className="footer__paragraph"><FaEnvelope className='footer__icon'/>info@gotosndigital.com</p>
</div>
        </div>
      <hr className="color-white" />
      <p className='footer__pie'>Gotosndigital Copyright 2022 © All Reserved</p>
    </div>

   </section>
  )
}

export default Footer