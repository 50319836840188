import React, { useState } from 'react'
import {Link} from "react-scroll"
import { FaArrowRight, FaBars } from "react-icons/fa";
import { motion } from "framer-motion"
import Slider from "react-slick";
import 'animate.css';

const Header = () => {
  const [isAside, setAside] = useState(false)   
  const settings = {
    dots: true,
    autoplay:true,
    infinite: true,
    arrows:false,
    speed: 800,
    duration:5000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

const titleAnimate={
  offscreen:{
    x: -500, opacity:1
  },
  onscreen:{ x:0, opacity:1, transition:{ type: "spring", stiffness: 100, duration:1.2 } },
}
const textAnimate={
  offscreen:{
    x:-1200, opacity:1 
  },
  onscreen:{ x:0, opacity:1, transition:{ type: "spring", stiffness: 100, duration:1.2 } },
}
const buttonAnimate={
  offscreen:{
    x: -500, opacity:1 
  },
  onscreen:{ x:0, opacity:1, transition:{ type: "spring", stiffness: 100, duration:1.2 } },
}

  return (
 <>
  
<div  className='menuIcon'
onClick={()=>setAside(!isAside)}
>
<FaBars/>
</div>

  <aside className={isAside?'aside marginLeft':'aside'}>
  
  <Link
  activeClass="active_mobile"
  to="home"
  spy={true}
  smooth={true}
  offset={0}
  duration={500}
  className="aside__link"
  onClick={()=>setAside(!isAside)}
  >
Home
  </Link>
  <Link
  activeClass="active_mobile"
  to="about"
  spy={true}
  smooth={true}
  offset={0}
  duration={500}
  className="aside__link"
  onClick={()=>setAside(!isAside)}
  >
About Us
</Link>
<Link
  activeClass="active_mobile"
  to="services"
  spy={true}
  smooth={true}
  offset={0}
  duration={500}
  className="aside__link"
  onClick={()=>setAside(!isAside)}
>
Services
</Link>
<Link
  activeClass="active_mobile"
  to="contact"
  spy={true}
  smooth={true}
  offset={0}
  duration={500}
  className="aside__link"
  onClick={()=>setAside(!isAside)}
>
Contact Us
</Link>
  </aside>

 {/* end mobile menu */}
  <motion.header className='bg-white'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ type: "spring", stiffness: 100, duration:1.2 }}
  >
  <nav className="container menu">
  <div className='menu__logo'>
  <img src="/images/logo.png" alt="Gotosndigital" />
  <h2 className='color-red'>otosndigital</h2>
  </div>
 
  <div>
  <Link
    activeClass="active"
    to="home"
    spy={true}
    smooth={true}
    offset={-110}
    duration={500}
    className="menu__link"
  
  >
    Home
  </Link>
  <Link
    activeClass="active"
    to="about"
    spy={true}
    smooth={true}
    offset={-105}
    duration={500}
    className="menu__link"
  >
    About
  </Link>
  <Link
    activeClass="active"
    to="services"
    spy={true}
    smooth={true}
    offset={-105}
    duration={500}
    className="menu__link"
    
  >
    Services
  </Link>
  <Link
    activeClass="active"
    to="contact"
    spy={true}
    smooth={true}
    offset={-105}
    duration={500}
    className="menu__link"
  >
    Contact Us
  </Link>
  </div>
 </nav>
  </motion.header>
  {/* end header */}
 <section id="home" className="bg-white">

 <Slider {...settings}>
 <div className="slider"
 >
  <img src="/images/slider1.jpg" alt="Banner" className='slider__image' />
 <motion.div className="slider__content"
  initial={"offscreen"}
  animate={"onscreen"}
  transition={{staggerChildren:0.5}}
 >
 <motion.h2 className="slider__title"
 variants={titleAnimate}
 > We Transform Your Ideas</motion.h2>
 <motion.p className="slider__paragraph"
 variants={textAnimate}
 >SND believe in the concept of real transformation which means that not just systems and functions are transformed but the people the culture and management are also reimagined. We will help your business reimagine your purpose and mission for the Digital Age.</motion.p>

<Link
    activeClass="active"
    to="about"
    spy={true}
    smooth={true}
    offset={-105}
    duration={500}
  >
<motion.button className='slider__button'
variants={buttonAnimate}
>Get Started <FaArrowRight style={{ marginLeft: 5 }} /></motion.button>
</Link>
 </motion.div>
</div>
{/* end first slider */}
<div className="slider" style={{backgroundColor:"black", backgroundSize:"cover", width:"100%", height:"100vh"}}>
  <img src="/images/slider2.jpg" alt="Banner" className='slider__image' />
 <motion.div className="slider__content"
 initial={{opacity:0}}
 animate={{opacity:1}}
 >
 <h2 className="slider__title animate__fadeInLeft"> Delivering Digital Forward Business Solutions</h2>
 <p className="slider__paragraph">We use tried and tested business analysis tools and forward diversified strategies to help redefine your business and facilitate the move to digital</p>

<Link
    activeClass="active"
    to="about"
    spy={true}
    smooth={true}
    offset={-105}
    duration={500}
  >
<button className='slider__button'>Get Started <FaArrowRight style={{ marginLeft: 5 }} /></button>
</Link>
 </motion.div>
</div>
{/* end second slider */}
<div className="slider" style={{backgroundColor:"black", backgroundSize:"cover", width:"100%", height:"100vh"}}>
  <img src="/images/slider4.jpg" alt="Banner" className='slider__image' />
 <div className="slider__content">
 <h2 className="slider__title">Platform Development</h2>
 <p className="slider__paragraph">We believe that as a business we need to always provide our clients with the best-in-bred solution for their needs which is why we are platform agnostic and are happy to partner with all leading edge technology providers. Our solutions seamlessly integrate with the top 10 business fulfilment platforms from MIcrosoft suite to IBM business software and Salesforce Cloud</p>

<Link
    activeClass="active"
    to="about"
    spy={true}
    smooth={true}
    offset={-105}
    duration={500}
  >
<button className='slider__button'>Get Started <FaArrowRight style={{ marginLeft: 5 }} /></button>
</Link>
 </div>
</div>
{/* end third slider */}
 
 

   </Slider>
 </section>
 </>
  )
}

export default Header