import React from 'react'
import About from './components/About'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Header from './components/Header'
import Phrase from './components/Phrase'
import Services from './components/Services'
import Trusted from './components/Trusted'

const App = () => {
  return (
   <>
   <Header/>
   <Trusted/>
   <About/>
   <Services/>
   <Phrase/>
   <Contact/>
   <Footer/>
   </>
  )
}

export default App