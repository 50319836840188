import React from 'react'
import { motion} from "framer-motion"

const Services = () => {
  const titleAnimate={
    offscreen:{
      x: -200, opacity:0
    },
    onscreen:{ x:0, opacity:1, transition:{ type: "spring", stiffness: 100, duration:1.2 } },
  }
  const boxAnimate={
    offscreen:{
      y: 300, opacity:0
    },
    onscreen:{ y:0, opacity:1, transition:{ type: "spring", stiffness: 100, duration:1.2 } },
  }
  return (
    <>
    <motion.section className="services container" id="services"
   initial={{ opacity: 0, y:100}}
   transition={{duration:1}}
   whileInView={{opacity:1, y:95}}
  viewport={{amount:0.5}}
    >
      <h2 className='services__title' variants={titleAnimate}>
        <span className='color-red'>Our</span><span className='color-black'> Services</span>
      </h2>

      <div className="services__container"
  >
  <div className="box" variants={boxAnimate}>
  <img src="/images/people-about.jpg" alt="Business Process" className='box__image' />
  <h2 className="box__title">Business Process</h2>
  <p className="box__paragraph">
  Our services are designed to capture the full business cycle of your organisation
  From core Requirements Analysis, Project Planning, Vendor Selection ,Roadmapping
  Technology Delivery, Support framework  and enhanced Training
  </p>
  </div>
  {/* end box 1 */}
  <div className="box" variants={boxAnimate}>
  <img src="/images/slider4.jpg" alt="Development Platform" className='box__image'/>
  <h2 className="box__title">Development Platform</h2>
  <p className="box__paragraph">
  SND Utilise a dedicated flexible SND delivery framework which can be tailored to
  the specific need of the client /organisation. We understand how important projects
  Which transform the business can be. We integrate according to your needs
  </p>
  
  </div>
  {/* end box 2 */}
  <div className="box" variants={boxAnimate}>
  <img src="/images/data.jpg" alt="Data/CRM" className='box__image'/>
  <h2 className="box__title">Data/CRM</h2>
  <p className="box__paragraph">
  We provide leading edge CRM platform development and customisation with integral
  reporting for teams to streamline the management of company data assets ( Users
  Customers, Clients, Vendors , Investors , Customers ) We cater for all needs
  </p>
  </div>
  {/* end box 3 */}
    </div> 
    </motion.section>
     
    </>
  )
}

export default Services