import React,{useState} from "react";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const[mailMsg,setMailmsg]=useState("");
  const sendRequest = (data) => {
    console.log(data);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept","*/*");
  var requestOptions={
    method:'POST',
    body:JSON.stringify(data),
    headers:myHeaders,
    redirect:'follow'
}
  fetch("/sendEmail",requestOptions)
  .then(response=>response.json())
  .then(data=>{
    console.log(data);
    if(data.success){
     
      setMailmsg("Email send successfully")
      reset();
    }
    else{
     
      setMailmsg("Error occured while sending mail");
      reset();
    }
  })
  };
  return (
    <motion.section
      id="contact"
      className="contact container"
      initial={{ opacity: 0, y: 100 }}
      transition={{ duration: 1 }}
      whileInView={{ opacity: 1,y:105}}
      viewport={{ amount: 0.3 }}
    >
      <div className="contact__col_left">
        <h2 className="contact__title">Request Free Consultation</h2>
        <p className="contact__paragraph">
          Get in touch and discover how we can help. We aim to be in touch for a
          consultation{" "}
        </p>
        <img
          src="/images/equipo.jpg"
          alt="Contact Us"
          className="contact__image"
        />
      </div>
      <div className="contact__form">
        <form onSubmit={handleSubmit(sendRequest)}>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name..."
            className="contact__input"
           {...register("name", {
            required:true,
            maxLength:50,
            pattern: /^[a-záéíóúüñ' ]+$/i,
           })}
          />
          {
            errors.name?.type==='required' &&  <span className="msgError"> Name is required</span>
          }
          {errors.name?.type === "maxLength" && (
              <p className="msgError ">
                Name exceds the limit of 20 characters...
              </p>
            )}
            {errors.name?.type === "pattern" && (
              <p className="msgError ">Invalid format only letters...</p>
            )}
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email..."
            className="contact__input"
            {...register("email", {
              required: true,
              maxLength: 50,
              pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
            })}
          />
          {errors.email?.type === "required" && (
              <p className="msgError ">Email is required...</p>
            )}
            {errors.email?.type === "maxLength" && (
              <p className="msgError ">
                Email exceds the limit of 50 characters...
              </p>
            )}
            {errors.email?.type === "pattern" && (
              <p className="msgError ">Invalid format...</p>
            )}
          <input
            type="text"
            name="phone_number"
            id="phone_number"
            placeholder="Phone Number..."
            className="contact__input"
            {...register("phonenumber", {
              required: true,
              maxLength: 20,
              pattern: /^[0-9 ]+$/i,
            })}
          />
          {errors.phonenumber?.type === "required" && (
              <p className="msgError ">Phone Number is required...</p>
            )}
            {errors.phonenumber?.type === "maxLength" && (
              <p className="msgError ">
                Phone Number exceds the limit of 20 characters...
              </p>
            )}
            {errors.phonenumber?.type === "pattern" && (
              <p className="msgError ">Invalid format, only numbers...</p>
            )}
          <textarea
            name="comments"
            id=""
            cols="30"
            rows="10"
            className="contact__input"
            placeholder="Comments..."
            {...register("comments", {
              required: true,
              maxLength: 500,
            })}
          ></textarea>
          {errors.comments?.type === "required" && (
              <p className="msgError ">Comments is required...</p>
            )}
            {errors.comments?.type === "maxLength" && (
              <p className="msgError ">
                Comments exceds the limit of 500 characters...
              </p>
            )}
          <button className="contact__button">
            Send Request <FaArrowRight style={{ marginLeft: 5 }} />
          </button>
         
        </form>
        <span id="msgEmail"><p className="msgMail">{mailMsg}</p></span>
      </div>
    </motion.section>
  );
};

export default Contact;
